import React from 'react';

import Layout from '../components/layout';
import Logo from '../components/logo';
import Menu from '../components/menu';
import Section from '../components/section';
import Slider from '../components/slider';
import Footer from '../components/footer';
import { ModalContext } from '../components/context/Modal';

export default function IndexPage(props) {
  return (
    <Layout>
      <div className="header">
        <Menu {...props} />
        <div className="hero-section">
          <div className="logo-section">
            <Logo width="140" height="76" />
            <span className="company-name">Scioto Management Group</span>
          </div>
          <span className="tagline">Exceeding Expectations Everyday</span>
          <ModalContext.Consumer>
            {({ isModalOpen, setModalState }) => (
              <a onClick={() => setModalState(true)}>
                <button className="contact-button">Contact Us</button>
              </a>
            )}
          </ModalContext.Consumer>
          <div className="tagline-container">
            <div className="tagline-box">
              <div>
                Great people assembled with a team approach, equipped with the
                right tools and managed properly yield an asset that will grow
                in value and generate profit over the long term.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="body-container">
        <Section
          title="Understanding the Potential"
          body="To evaluate a property’s profit potential, we begin with reviewing
        global and national economic forces, then work our way inward to the
        property and its immediate sub market. Each potential asset must match
        the parameters of the acquisition formula, including critical mass,
        square footage or number of units along with a thorough understanding
        of the upside potential and downside hedge."
          linkText="See our communities"
          backgroundName="understanding"
          left
        />
        <div className="right-container">
          <Section
            title="The Fundamentals"
            body="Through a fundamental approach to relationship-based business practices,
          a strong hands-on approach to property management, seasoned real estate experience
          and entrepreneurial spirit, coupled with team energy and the dedication of its
          associates, the Scioto Management Group provides to its investors, owners, and
          clients the benefits of high productivity, low vacancy rates, timely reporting
          and excellent financial controls."
            linkText="Learn more about what we do"
            backgroundName="fundamentals"
          />
        </div>
        <Section
          title="Our Commitment to You"
          body="SMG is a small boutique property management company, located in
          Columbus, Ohio. Our small size enables us to offer personalized attention
          to our client’s properties.  We are business people who couple grass roots
          research with macro-economics to see the potential acquisitions as they really
          are… because events around the country can affect profit potential just as
          much as events across the street."
          linkText="Learn more about who we are"
          backgroundName="commitment"
          left
        />
        <Slider />
      </div>
      <Footer />
    </Layout>
  );
}
